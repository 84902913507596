import React from "react"
import { Button, Col, Row, Typography } from "antd"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Shell from "../components/Shell/Shell"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import { darken, lighten } from "../utils/color"

const description = `Plan your trip to Asheville, NC. The Residences at Biltmore is centrally located to all that Asheville has to offer, include world-class spas, breweries, and outdoor adventures, and the peerless Biltmore. With our 24 hour service, let us help you get the most out of your stay.`

const centeredRow = {
  align: `middle`,
  justify: `center`,
  type: `flex`,
}

const normalCol = {
  xs: 23,
  sm: 22,
  md: 20,
  lg: 18,
  xl: 16,
  xxl: 14,
}

const tan = `#F2F0E9`

const packageRowStyle = {
  background: lighten(tan, 5),
  border: `1px solid ${darken(tan, 2)}`,
  borderRadius: 4,
  boxShadow: `0 4px 8px rgba(0,0,0,0.1)`,
  padding: 12,
}

const Package = ({
  description,
  image,
  name,
  page: { slug },
  swapLayout = false,
}) => (
  <Row {...centeredRow} gutter={[24, 24]} style={packageRowStyle}>
    <Col
      xs={{ order: 1, span: 24 }}
      md={{ order: 1, span: 20 }}
      lg={{ order: swapLayout ? 2 : 1, span: 12 }}
    >
      <Img alt={image.description} fluid={image.fluid} />
    </Col>
    <Col
      xs={{ order: 2, span: 24 }}
      md={{ order: 1, span: 20 }}
      lg={{ order: swapLayout ? 1 : 2, span: 12 }}
    >
      <Typography.Title level={2} style={{ marginTop: 24 }}>
        {name}
      </Typography.Title>
      <div
        dangerouslySetInnerHTML={{
          __html: description.childMarkdownRemark.html,
        }}
        style={{ marginBottom: 24 }}
      />
      <Link to={`/${slug}/`}>
        <Button size="large" type="primary">
          Learn More
        </Button>
      </Link>
    </Col>
  </Row>
)

export default ({ data: { hero, packages }, location }) => (
  <Shell>
    <SEO
      meta={{
        description,
        image: hero.fluid.src,
        imageAlt: hero.description,
      }}
      title="Things to Do in Asheville - The Residences at Biltmore"
      url={location.href}
    />
    <Hero contentfulAsset={hero} height="40vh" />
    <BookingBar />
    <Row {...centeredRow} style={{ marginTop: 32 }}>
      <Col {...normalCol} style={{ textAlign: `center` }}>
        <Typography.Title>Things To Do</Typography.Title>
      </Col>
    </Row>
    <Row {...centeredRow} style={{ marginTop: 8 }}>
      {packages.nodes.map((pkg, index) => (
        <Col key={index} {...normalCol} style={{ margin: `24px auto` }}>
          <Package {...pkg} swapLayout={!!(index % 2)} />
        </Col>
      ))}
    </Row>
  </Shell>
)

export const frag = graphql`
  fragment PackageImage on ContentfulAsset {
    description
    fluid(maxWidth: 450) {
      ...GatsbyContentfulFluid_withWebp
    }
  }
`

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "couplebyfirepitFeaturedImage" }) {
      ...HeroFluid
    }
    packages: allContentfulPackage(
      filter: {
        business: { elemMatch: { name: { in: "Residences at Biltmore" } } }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          ...PackageImage
        }
        name
        page {
          slug
        }
      }
    }
  }
`
