const standardizeHex = color => {
  const col = color.replace(`#`, ``)
  return col.length === 3 ? `${col}${col}` : col
}

const standardizeRGB = rgb =>
  Array.isArray(rgb)
    ? rgb
    : rgb
        .replace(/[^0-255]/g, ` `)
        .trim()
        .split(` `)
        .filter(x => x)
        .map(x => parseInt(x))

// const standardizeHSL = hsl => standardizeRGB(hsl)

const formatHSL = hsl => {
  const [h, s, l] = hsl
  return `hsl(${h},${s}%,${l}%)`
}

const conform = (value, ceiling = 255, floor = 0) =>
  value > ceiling ? ceiling : value < floor ? floor : value

const hexify = value => {
  const hex = value.toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

const hexToRGB = hex =>
  standardizeHex(hex)
    .replace(/(.{2})(?!$)/g, `$1,`)
    .split(`,`)
    .map(color => conform(parseInt(color, 16)))

const hexToHSL = hex => rgbToHSL(hexToRGB(hex))

const rgbToHex = rgb =>
  standardizeRGB(rgb).reduce((acc, cur) => `${acc}${hexify(cur)}`, `#`)

const shade = (color, percent) =>
  rgbToHex(
    hexToRGB(color).map(rgb => conform(parseInt((rgb * (100 + percent)) / 100)))
  )

export const rgbToHSL = rgb => {
  const [r, g, b] = standardizeRGB(rgb).map(val => val / 255)
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0,
    s = 0,
    l = 0

  if (delta === 0) h = 0
  else if (cmax === r) h = ((g - b) / delta) % 6
  else if (cmax === g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)
  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

  l = +(l * 100).toFixed(1)
  s = +(s * 100).toFixed(1)

  return [h, s, l]
}

export const getComplement = color =>
  rgbToHex(hexToRGB(color).map(val => 255 - val))

export const saturate = (color, percent = 25) =>
  formatHSL(
    hexToHSL(color).map((val, index) =>
      index === 1 ? conform(val + percent, 100) : val
    )
  )

export const desaturate = (color, percent = 25) =>
  saturate(color, -Math.abs(percent))

export const darken = (color, percent) => shade(color, -Math.abs(percent))

export const lighten = (color, percent) => shade(color, Math.abs(percent))

export const transparentize = (color, percent) =>
  `rgba(${hexToRGB(color).join(`,`)},${
    Math.round((1 - percent / 100) * 100) / 100
  })`
